<template>
  <div class="user-management">
    <div class="user-management-header">
      <v-btn class="mr-3" @click="$router.go(-1)"> {{ $t("GlobalUI.Cancel") }} </v-btn>
      <v-btn color="primary" @click="save" :loading="isBusy"> {{ $t("GlobalUI.Save") }} </v-btn>
    </div>
    <v-alert v-if="errorCode" text outlined color="deep-orange" icon="mdi-fire" dismissible>
      {{ $t(`ServerResponse.${errorCode}`) }}
    </v-alert>
    <div class="user-management-grid" :class="{ 'two-cols': user.id }">
      <v-user-general-card :user="user" @change:user="handleChangeUser" full-height />
      <v-user-password-card
        v-if="user.id"
        :user="user"
        @change:user="handleChangeUser"
        full-height
      />
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";

import VUserGeneralCard from "./cards/VUserGeneralCard";
import VUserPasswordCard from "./cards/VUserPasswordCard";

import usersService from "../../services/users.service";
import { username, PASSWORD_MAX_LENGTH } from "../../services/validators.service";

import { toast } from "../../helpers/dialogs";

import { serverErrorCodes } from "../../constants/response";

const { INTERNAL_SERVER_ERROR } = serverErrorCodes;

const userSchema = () => ({
  avatar: null,
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  repeatPassword: "",
  role: null,
});

export default {
  name: "VUserManagement",

  components: { VUserGeneralCard, VUserPasswordCard },

  mixins: [validationMixin],

  validations: {
    user: {
      role: { required },
      username: { required, username },
      email: { required, email },
      firstName: { required },
      lastName: { required },
      password: { minLength: minLength(PASSWORD_MAX_LENGTH) },
      repeatPassword: { sameAsPassword: sameAs("password") },
    },
  },

  provide() {
    return {
      $v: this.$v,
    };
  },

  data() {
    return {
      user: userSchema(),
      errorCode: null,

      isBusy: false,
      isUserLoading: false,
    };
  },

  async created() {
    const userId = this.$route.params.userId;

    if (userId) {
      this.isUserLoading = true;
      const user = await usersService.getById(userId);
      this.user = { ...this.user, ...user };
      this.isUserLoading = false;
    }
  },

  methods: {
    handleChangeUser({ value, field }) {
      this.user[field] = value;
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isBusy = true;

      const user = { ...this.user };
      delete user.repeatPassword;

      try {
        if (!this.user.id) {
          const userResponse = await usersService.create(user);
          if (userResponse) this.user.id = userResponse.id;
        } else await usersService.updateById(this.user.id, user);

        await this.$dialog.notify.success(...toast("Changes Saved Successfully"));
        this.$router.push({ name: "users" });
      } catch ({ response }) {
        if (!response || !response.data) this.errorCode = INTERNAL_SERVER_ERROR;
        else this.errorCode = response.data.code || INTERNAL_SERVER_ERROR;
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-management-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.user-management-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  &.two-cols {
    grid-template-columns: 2fr 1fr;
  }
}

@media screen and (max-width: 991px) {
  .user-management-grid {
    grid-template-columns: 100%;
    &.two-cols {
      grid-template-columns: 100%;
    }
  }
}
</style>