import { render, staticRenderFns } from "./VUserGeneralCard.vue?vue&type=template&id=7c7ad854&scoped=true&"
import script from "./VUserGeneralCard.vue?vue&type=script&lang=js&"
export * from "./VUserGeneralCard.vue?vue&type=script&lang=js&"
import style0 from "./VUserGeneralCard.vue?vue&type=style&index=0&id=7c7ad854&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7ad854",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VDivider,VSelect,VTextField,VToolbar,VToolbarTitle})
