<template>
  <v-card class="user-general-card" :class="{ 'full-height': fullHeight }" elevation="2">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("GlobalUI.General") }}</v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text class="user-general-card__body">
      <div class="user-general-avatar">
        <v-photo-uploader
          class="avatar-uploader"
          :photo="user.avatar"
          size="sm"
          type="AVATAR"
          @upload="(value) => $emit('change:user', { value, field: 'avatar' })"
          @clear="() => $emit('change:user', { value: null, field: 'avatar' })"
        />
      </div>
      <div class="user-general-controls">
        <v-select
          :value="user.role"
          @change="$emit('change:user', { value: $event, field: 'role' })"
          :items="rolesList"
          :label="$t('Users.Role')"
          item-value="token"
          return-object
          :error-messages="roleErrors"
          @blur="$v.user.role.$touch()"
        >
          <template #selection="{ item }">{{ $t(`Users.Role${item.label}`) }}</template>
          <template #item="{ item }">{{ $t(`Users.Role${item.label}`) }}</template>
        </v-select>
        <v-text-field
          :value="user.username"
          @input="$emit('change:user', { value: $event, field: 'username' })"
          :label="$t('Users.UserName')"
          :error-messages="usernameErrors"
          @blur="$v.user.username.$touch()"
        />
        <v-text-field
          :value="user.email"
          @input="$emit('change:user', { value: $event, field: 'email' })"
          label="E-Mail"
          :error-messages="emailErrors"
          @blur="$v.user.email.$touch()"
        />
        <v-text-field
          :value="user.firstName"
          @input="$emit('change:user', { value: $event, field: 'firstName' })"
          :label="$t('Users.FirstName')"
          :error-messages="firstNameErrors"
          @blur="$v.user.firstName.$touch()"
        />
        <v-text-field
          :value="user.lastName"
          @input="$emit('change:user', { value: $event, field: 'lastName' })"
          :label="$t('Users.LastName')"
          :error-messages="lastNameErrors"
          @blur="$v.user.lastName.$touch()"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VPhotoUploader from "../../photos/VPhotoUploader";

import usersService from "../../../services/users.service";

import { USERNAME_MIN_LENGTH, USERNAME_MAX_LENGTH } from "../../../services/validators.service";

const userKeys = ["avatar", "username", "firstName", "lastName", "email", "role"];

export default {
  name: "VUserGeneralCard",

  components: { VPhotoUploader },

  props: {
    user: {
      type: Object,
      required: true,
      validator: (user) => userKeys.every((key) => key in user),
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },

  inject: ["$v"],

  data: () => ({
    rolesList: [],
  }),

  computed: {
    roleErrors() {
      const errors = [];
      if (!this.$v.user.role.$dirty) return errors;
      !this.$v.user.role.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.user.username.$dirty) return errors;
      !this.$v.user.username.required && errors.push(this.$t("Validation.FieldRequired"));
      !this.$v.user.username.username &&
        errors.push(
          this.$t("Validation.UsernameHelper", {
            min: USERNAME_MIN_LENGTH,
            max: USERNAME_MAX_LENGTH,
          }),
        );
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) return errors;
      !this.$v.user.email.required && errors.push(this.$t("Validation.MailRequired"));
      !this.$v.user.email.email && errors.push(this.$t("Validation.Mail"));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.user.firstName.$dirty) return errors;
      !this.$v.user.firstName.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.user.lastName.$dirty) return errors;
      !this.$v.user.lastName.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
  },

  async created() {
    const rolesList = await usersService.getAllRoles();
    this.rolesList = rolesList;
  },
};
</script>

<style lang="scss" scoped>
.user-general-card {
  .full-height {
    height: 100%;
  }

  &__body {
    display: grid;
    grid-template-columns: 240px auto;
    gap: 25px;
  }
}

.user-general-avatar {
  .avatar-uploader {
    max-width: 300px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .user-general-card {
    &__body {
      grid-template-columns: 200px auto;
    }
  }
}

@media screen and (max-width: 576px) {
  .user-general-card {
    &__body {
      grid-template-columns: 100%;
    }
  }
}
</style>